import { createInjectionMiddleware, IMiddlewareDecorator,
     truncateDisplayName } from '@lardy/core';
import { observer } from 'mobx-react-lite';
import { ComponentType, createElement } from 'react';
import { ResponsivityContext } from './context';
import { Breakpoint, IResponsivityContext } from './interfaces';

export const decorator: IMiddlewareDecorator<IResponsivityContext> = (
    opts: {breakpoints?: {[K in Breakpoint]: number}}
) =>
    (getProvider: (store: IResponsivityContext) => ComponentType<any>) => {
        return <P>(Component: ComponentType<P>) => {
            const store = new ResponsivityContext();

            if (opts.breakpoints !== undefined) {
                store.setBreakpoints(opts.breakpoints);
            }

            const Provider = getProvider(store);

            const ResponsivityContextProvider = observer((props: P) => {
                return createElement('div', {
                    ref: store.setAppRef
                }, createElement(Provider, {}, createElement(Component, props)));
            });

            (ResponsivityContextProvider as any).displayName = truncateDisplayName(
                Component.displayName,
                'ResponsivityContext'
            );

            return ResponsivityContextProvider;
        };
    };

export const responsivityContext = createInjectionMiddleware<IResponsivityContext>('ResponsivityContext', decorator);

export const provider = (breakpoints: { [K in Breakpoint]: number }) => responsivityContext.provider({ breakpoints });

const hook = responsivityContext.hook;

export const APP_SIZE           = hook('APP_SIZE', 'app');
export const WINDOW_SIZE        = hook('WINDOW_SIZE', 'window');
export const SET_BREAKPOINTS    = hook('SET_BREAKPOINTS', 'setBreakpoints');
export const APP_WIDTH          = hook('APP_WIDTH', 'appWidth');
export const WINDOW_WIDTH       = hook('WINDOW_WIDTH', 'windowWidth');
