import { ResizeSensor } from 'css-element-queries';
import { action, computed, observable } from 'mobx';
import { Breakpoint, IResponsivityContext } from './interfaces';

export class ResponsivityContext implements IResponsivityContext {

    @computed public get window(): Breakpoint {
        return this.getResponsivityForWidth(this.windowWidth);
    }
    @computed public get app() {
        return this.getResponsivityForWidth(this.appWidth);
    }

    @computed public get windowWidth() {
        return this._windowWidth !== null ? this._windowWidth : this.breakpoints.lg;
    }

    @computed public get appWidth() {
        return this.appRect !== null ? this.appRect.width : this.breakpoints.lg;
    }

    @observable private breakpoints: {
        [K in Breakpoint]: number
    } = {
        sm: 0,
        md: 768,
        lg: 1024
    };
    @observable private appRect: ClientRect | null = null;
    @observable private ref: HTMLElement | null = null;
    @observable private _windowWidth: number | null = null;

    private resizeSensor: ResizeSensor;

    public constructor() {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', this.updateRects);
            window.addEventListener('scroll', this.updateRects);
        }
    }

    @action public setAppRef = (ref: HTMLElement | null) => {
        if (ref === null || ref === this.ref) {
            return;
        }

        if (this.resizeSensor !== undefined) {
            this.resizeSensor.detach();
        }

        this.ref = ref;

        this.resizeSensor = new ResizeSensor(ref, () => {
            this.updateRects();
        });

        this.updateRects();
    }

    @action public setBreakpoints = (breakpoints: {
        [K in Breakpoint]: number
    }) => {
        this.breakpoints = breakpoints;
    }

    @action private updateRects = () => {
        if (typeof window !== 'undefined') {
            this._windowWidth = window.innerWidth;
        }

        if (this.ref !== null) {
            this.appRect = this.ref.getBoundingClientRect();
        }
    }

    private getResponsivityForWidth = (width: number): Breakpoint => {
        if (width >= this.breakpoints.lg) {
            return 'lg';
        } else if (width >= this.breakpoints.md) {
            return 'md';
        }

        return 'sm';
    }
}
